import * as serializers from '@/constants/serializer';
import * as schemaItemTypes from './schemaItemTypes';
import * as onUpdateEffects from './onUpdateEffects';

const dependentProperties = {
  BUILDINGS: 'buildings',
  PROJECTS: 'projects',
  UNITS: 'units',
  ROOMS: 'rooms',
  CLIENTS: 'clients',
  PAYMENT_TYPE: 'paymentTypes',
};

export const schemaWithoutPaymentPeriod = [
  {
    type: schemaItemTypes.DATE,
    label: 'label.invoice_period',
    prop: 'invoicePeriod',
    clearable: true,
    multiple: true,
  },
  {
    type: schemaItemTypes.MANY_PROJECTS,
    label: 'label.projects',
    prop: dependentProperties.PROJECTS,
    payload: [
      {
        param: 'buildings',
        from: [dependentProperties.BUILDINGS],
        serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
        onlyEffect: true,
      },
    ],
    onUpdate: [
      {
        type: onUpdateEffects.RESET,
        to: dependentProperties.BUILDINGS,
      },
    ],
  },
  {
    type: schemaItemTypes.MANY_BUILDINGS,
    label: 'label.buildings',
    prop: dependentProperties.BUILDINGS,
    payload: [
      {
        param: 'projects',
        from: [dependentProperties.PROJECTS],
        cache: true,
        serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
      },
      {
        param: 'units',
        from: [dependentProperties.UNITS],
        serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
        onlyEffect: true,
      },
    ],
    onUpdate: [
      {
        type: onUpdateEffects.AUTOCOMPLETE_MANY,
        to: dependentProperties.PROJECTS,
      },
      {
        type: onUpdateEffects.RESET,
        to: dependentProperties.UNITS,
      },
    ],
  },
  {
    type: schemaItemTypes.MANY_UNITS,
    label: 'label.units',
    prop: dependentProperties.UNITS,
    payload: [
      {
        param: 'projects',
        from: [dependentProperties.PROJECTS],
        cache: true,
        serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
      },
      {
        param: 'buildings',
        from: [dependentProperties.BUILDINGS],
        cache: true,
        serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
      },
      {
        param: 'rooms',
        from: [dependentProperties.ROOMS],
        onlyEffect: true,
        serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
      },
      {
        param: 'clients',
        from: [dependentProperties.CLIENTS],
        onlyEffect: true,
        serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
      },
    ],
    onUpdate: [
      {
        type: onUpdateEffects.AUTOCOMPLETE_MANY,
        to: dependentProperties.BUILDINGS,
      },
      {
        type: onUpdateEffects.RESET,
        to: dependentProperties.CLIENTS,
      },
    ],
  },
  {
    type: schemaItemTypes.MANY_ROOMS,
    label: 'label.rooms',
    prop: dependentProperties.ROOMS,
    access: ['rooms'],
    payload: [
      {
        param: 'projects',
        from: [dependentProperties.PROJECTS],
        cache: true,
        serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
      },
      {
        param: 'buildings',
        from: [dependentProperties.BUILDINGS],
        cache: true,
        serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
      },
      {
        param: 'units',
        from: [dependentProperties.UNITS],
        cache: true,
        serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
      },
    ],
    onUpdate: [
      {
        type: onUpdateEffects.AUTOCOMPLETE_MANY,
        to: dependentProperties.UNITS,
      },
      {
        type: onUpdateEffects.RESET,
        to: dependentProperties.CLIENTS,
      },
    ],
  },
  {
    type: schemaItemTypes.MANY_CLIENTS,
    label: 'label.clients',
    prop: dependentProperties.CLIENTS,
    payload: [
      {
        param: 'projects',
        from: [dependentProperties.PROJECTS],
        cache: true,
        serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
      },
      {
        param: 'buildings',
        from: [dependentProperties.BUILDINGS],
        cache: true,
        serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
      },
      {
        param: 'units',
        from: [dependentProperties.UNITS],
        cache: true,
        serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
      },
    ],
    onUpdate: [
      {
        type: onUpdateEffects.AUTOCOMPLETE_MANY,
        to: dependentProperties.UNITS,
      },
    ],
  },
];

/** @type {import('./schema').default}  */
export const schema = [
  {
    type: schemaItemTypes.DATE,
    label: 'label.payment_period',
    prop: 'paymentPeriod',
    clearable: true,
    multiple: true,
  },
  ...schemaWithoutPaymentPeriod,
  {
    type: schemaItemTypes.MANY_PAYMENT_TYPE,
    label: 'label.payment_types',
    prop: dependentProperties.PAYMENT_TYPE,
  },
];

export const fullSchema = [...schema];

export { schema as default };
